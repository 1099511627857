import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "../views/Layout.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

const routes = [
    {
        path: "/",
        name: "Layout",
        component: Layout,
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                path: "/",
                name: "home",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "久升优选",
                },
                component: () => import("@/views/home/index"),
            },
            {
                path: "/home",
                name: "homeactive",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "久升优选",
                },
                component: () => import("@/views/home/index"),
            },
            {
                path: "/productlist",
                name: "productlist",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "我的收益",
                },
                component: () => import("@/views/home/productlist"),
            },
            {
                path: "/treasure",
                name: "treasure",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "AI矩阵图",
                },
                component: () => import("@/views/treasure/index"),
            },
            {
                path: "/rank",
                name: "rank",
                meta: {
                    requiresAuth: false,
                    title: "全网排位赛",
                },
                component: () => import("@/views/rank/index"),
            },
            {
                path: "/ai",
                name: "ai",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "七宝AI",
                },
                component: () => import("@/views/ai/index"),
            },
            {
                path: "/detent",
                name: "detent",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "我的团队",
                },
                component: () => import("@/views/detent/index"),
            },
            {
                path: "/my",
                name: "my",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "个人中心",
                },
                component: () => import("@/views/my/index"),
            },
            {
                path: "/my/profit",
                name: "profit",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "全网分润",
                },
                component: () => import("@/views/profit/index"),
            },
            {
                path: "/myorder",
                name: "myorder",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "订单记录",
                },
                component: () => import("@/views/my/myorder"),
            },
            {
                path: "/myaddress",
                name: "myaddress",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "地址管理",
                },
                component: () => import("@/views/myaddress/index"),
            },
            {
                path: "/imgText",
                name: "imgText",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "项目简介",
                },
                component: () => import("@/views/my/imgText"),
            },
            {
                path: "/carry",
                name: "carry",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "提现申请",
                },
                component: () => import("@/views/carry/index"),
            },
            {
                path: "/carryrecord",
                name: "carryrecord",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "提现记录",
                },
                component: () => import("@/views/carry/carryrecord"),
            },
            {
                path: "/teamcarryrecord",
                name: "teamcarryrecord",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "团队提现记录",
                },
                component: () => import("@/views/carry/teamcarryrecord"),
            },
            {
                path: "/income",
                name: "income",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "我的收益",
                },
                component: () => import("@/views/carry/income"),
            },
            {
                path: "/myinvite",
                name: "myinvite",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "专属邀请码",
                },
                component: () => import("@/views/myinvite/index"),
            },
            {
                path: "/myteam",
                name: "myteam",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "团队管理",
                },
                component: () => import("@/views/myteam/index"),
            },
            {
                path: "/adment",
                name: "adment",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "广告",
                },
                component: () => import("@/views/advertisement/index"),
            },
            {
                path: "/integral",
                name: "integral",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "旅游卡兑换区",
                },
                component: () => import("@/views/integral/index"),
            },
            {
                path: "/coupons",
                name: "coupons",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "旅游卡商城",
                },
                component: () => import("@/views/coupons/index"),
            },
            {
                path: "/couponCashier",
                name: "couponCashier",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "收银台",
                },
                component: () => import("@/views/coupons/Cashier"),
            },
            {
                path: "/shop",
                name: "shop",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "兑换区",
                },
                component: () => import("@/views/shop/index"),
            },
            {
                path: "/baoping",
                name: "baoping",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "爆品区",
                },
                component: () => import("@/views/shop/baoping"),
            },
            {
                path: "/jingping",
                name: "jingping",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "精品区",
                },
                component: () => import("@/views/shop/jingping"),
            },
            {
                path: "/upshop",
                name: "upshop",
                meta: {
                    requiresAuth: false,
                    isShow: false,
                    title: "兑换区",
                },
                component: () => import("@/views/shop/upindex"),
            },
            {
                path: "/cashier",
                name: "cashier",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "收银台",
                },
                component: () => import("@/views/shop/Cashier"),
            },
            {
                path: "/shopdetail",
                name: "shopdetail",
                meta: {
                    requiresAuth: false,
                    isShow: true,
                    title: "商品详细",
                },
                component: () => import("@/views/shop/detail"),
            }
        ],
    },
    {
        path: "/login",
        name: "login",
        meta: {
            requiresAuth: true,
            title: "登录",
        },
        component: Login,
    },
    {
        path: "/Updatepw",
        name: "Updatepw",
        meta: {
            requiresAuth: true,
            title: "修改密码",
        },
        component: () => import("@/views/updatepw"),
    },
    {
        path: "/setBaozhu",
        name: "setBaozhu",
        meta: {
            requiresAuth: true,
            title: "转账",
        },
        component: () => import("@/views/setBaozhu"),
    },
    {
        path: "/Register",
        name: "Register",
        meta: {
            requiresAuth: true,
            title: "注册",
        },
        component: Register,
    },
    {
        path: "/download",
        name: "download",
        meta: {
            requiresAuth: true,
            title: "下载app",
        },
        component: () => import("@/views/download"),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : "久升优选";
    // window.screenTop(0);
    if (to.meta.requiresAuth) {
        next(); // 允许通过
    } else {
        let token = localStorage.getItem("token");
        if (token == null || token == "" || token == undefined) {
            router.push("/Login");
        } else {
            next(); // 允许通过
        }
    }
});

export default router;
